import React from 'react'

import Container from 'react-bootstrap/Container';

import Footer from '../components/footer'
import Header from '../components/header'
import Meta from '../components/meta'

const NotFound = ({ location }: Props) => {
  return (
    <>
      <Meta />
      <header>
        <a id="top" />
        <Header location={location} />
      </header>
      <div id="wrapper" className="d-flex flex-column h-100">
        <main className="flex-shrink-0">
          <Container fluid="md">
            <h1>Page Not Found</h1>
            <p>
              {`Sorry, can't find it.`}
            </p>
          </Container>
        </main>
        <footer className="full-width mt-auto">
          <Footer />
        </footer>
      </div>
    </>
  )
}

export default NotFound
